.team-section{
    padding:100px 0;
    position:relative;
    background: #0f1011;
}
.opaque-text{
    opacity: 0.05;
    font-family:'syne',sans-serif;
    font-weight: bold;
    font-size: 160px;
    z-index:-1;
    position: absolute;
}
.team-section .opaque-text{
    left:0;
}


.team-section .title h2{
    font-size:44px;
    padding-left:80px;
}

.team-section .team-photo{
    position: relative;
}

.team-section .team-photo img {
    width:550px;
    height:400px;
    object-fit: cover;
}

.team-section .team-photo .slide{
    transform-style: preserve-3d;
    transform: perspective(500px);
    position: relative;
    width:550px;
    height:400px;
    overflow: hidden;
}

.team-section .team-photo .slide .memberImg{
    position: absolute;
    top:-15%;
    left:18%;
    width:300px;
    height:400px;
    object-fit: cover;
    z-index:1;
}

.team-section .sub-title h3{
    font-size:30px;
}

.navigation .arrows{
    display:inline-flex;
    list-style: none;
    padding: 0;
}

.alignbottom{
    position: absolute;
    bottom:0;
}

/* hover on arrows */
.navigation .arrows .arrow-left{
    transition: 0.6s;
}
.navigation .arrows .arrow-left:hover{
    transform: scale(0.95);  
}

.navigation .arrows .arrow-right{
    transition: 0.6s;
}
.navigation .arrows .arrow-right:hover{
    transform: scale(0.95);  
}

.navigation .arrows li{
    border-radius: 50px;
    margin-right:20px;
    border:1px solid #000;
    width:65px!important;
    height:65px!important;
}

.navigation .arrows li img{
    width: 65px;
    padding: 25px 15px;
}

.team-section .profile{
    padding:50px 0 ;
}
.team-section .profile ul{
    display: flex;
    list-style: none;
    padding: 0;
}

.team-section .profile ul li{
    padding-right:30px;
    width:25%;
}

.team-section .profile  button{
    background:transparent;
    color:#fff;
    border:none;
    font-size:24px;
    padding:0; 
}

.team-section .profile button a {
    color:#000;
    text-decoration: none;
}

.team-section .profile button svg{
    background:#EC1A1A;
    border:none;
    border-radius:50%;
    height:35px;
    width:35px;
    padding:5px 10px;
    margin-left:10px;
    transition:0.5s;
}

.team-section .profile button:hover svg{
    transform: rotate(45deg);
}

#teamBg{
    position: absolute;
    left:0;
    width:700px;
    height:350px;
}

/* //carousel team */


.teamslider{
    width:100%;  
    position: relative;
    display: flex;
}
.slide-inner{
    width:450px;
    height:600px;
    /* overflow: hidden; */
    position: relative;
    margin:0px 30px 0px 0px;  
}


@keyframes slide {
    0%{
        transform: translate(375px, 110px) scaleX(0.28) scaleY(0.3);
        z-Index:9;
    }
    100%{
        /* transform:translate(0px, 0px); */
        z-index:9;
    }
}
@keyframes slide-back {
    0%{
        
        transform:translate(0px, 0px) scale(1); 
        z-Index:9;
    }
    100%{
        transform: translate(375px, 110px) scaleX(0.28) scaleY(0.3);
        z-Index:9;
    }
}
.slide-inner .img-container{
    width:400px;
    height:500px;
    clip-path: polygon(20% 0%, 100% 0, 100% 20%, 100% 80%, 80% 100%, 0 100%, 0% 80%, 0% 20%);
}
.slide-inner img{
    width:100%;
    height:100%;
    object-fit: cover;
    object-position: top;
    transition: 0.9s ease;
}
.slide-inner .info{
    background-color: #000;
    color:#fff;
    width:300px;
    padding:15px 15px;
    position: absolute;
    bottom:15%;
    left:0%; 
}

.team-info{
    height:220px;
}

@keyframes slide-left {
    0%{
        transform: translate(-100%,0);
    }
    100%{
        transform: translate(0,0);
    }
}
/* .slide-inner:hover  .info{
    left:0%;
    transition: 0.6s ease;
} */
.slide-inner .info h3{
    font-size:20px!important;
}
.slide-inner .info h4{
    font-size:16px!important;
}

.slide-inner .info p{
    color:#000;
    font-size:14px!important;
}

.slider-prev{
    position: absolute;
    left:0;
    bottom: 0;
    width:540px;
    height:650px;
    z-index:-1;
    overflow: hidden;
    object-fit: cover; 
    clip-path: polygon(20% 0%, 100% 0, 100% 20%, 100% 80%, 80% 100%, 0 100%, 0% 80%, 0% 20%);
    
}
@keyframes disappear{
    0%{
        opacity: 1;
    }
    100%{
        opacity: 0;
    }
}
@keyframes reappear{
    0%{
        opacity: 0;
    }
    100%{
        opacity: 1;
    }
}


.slider-prev .prev-slide-content .info{
    background-color: #EC1A1A;
    color:#fff;
    width:300px;
    padding:10px 15px;
    position: absolute;
    bottom:0;
}

.prev-slide-content  .info h3{
    font-size:20px;
}
.prev-slide-content  .info h4{
    font-size:16px;
}

.prev-slide-content  .info p{
    color:#000;
    font-size:14px!important;
}
.first{
    position: relative;
}
.first .info{
    background-color: #bf2b2b;
    color:#fff;
    width:120px;
    padding:5px 10px;
    position: absolute;
    bottom:0;
    opacity: 0;
}
.first  .info h3{
    font-size:6px;
    margin: 0;
}
.first  .info h4{
    font-size:3px;
    margin: 0;
}

.first .info p{
    color:#000;
    font-size:3px!important;
}
.about-team {
    padding: 80px 0px 0 100px;
    width:80%;
    position: relative;
}

.about-team h2{
    font-size: 40px!important;
    font-weight:300!important;
}

.about-team h5{
    font-family: 'Poppins';
    font-size: 22px!important;
    font-weight:300!important;
}
.about-team p{
    text-align:justify;
    font-size:17px!important;
    color:#fff;
    font-weight:300;
    font-family: 'Roboto';
    margin:20px 0 40px;
}
.slider-list {
    display: flex;
    gap:100px;
}
.navigation-team{
    width:35%;
    position: absolute;
    bottom:2%;
    right:0;

}
.navigation-team svg{
    scale:0.8;
    transition: 0.8s ease;
}
.navigation-team svg:hover{
    scale:1;
    transition: 0.8s ease;
}

.slider-list .navigation-team div:first-child{
    padding-right:20px;
}
.slider-list .navigation-team div{
    cursor: pointer;
}
.slider-list .navigation-team div svg{
    transition: 0.7s;
}
.slider-list .navigation-team div:hover svg{
    scale: 0.9;
}

.team-section .team-photo .slide .social{
    opacity: 0;
    display: block;
}

.team-section .team-photo .slide .social i{
    padding:10px;   
    color:#fff; 
}

.sub-slide {
    position: relative; 
    overflow: hidden;
    clip-path: polygon(20% 0%, 100% 0, 100% 20%, 100% 80%, 80% 100%, 0 100%, 0% 80%, 0% 20%);  
   
}

.slideright{
}


@keyframes slide-right {
    0%{
        transform: translate(250px,0);
    }
    100%{
        transform: translate(0,0);
    }
}
@keyframes reverse-slide-left {
    0%{
        transform: translate(0,0);
    }
    100%{
        transform: translate(250px,0);
    }   
}

.sub-slide img
{ 
    width:250px; 
    height:250px;
    object-fit: cover;
    object-position: top ;
}




/* /responsive/ */

@media only screen and (max-width: 480px){

    .title h2 {
        font-size:32px!important; 
    }

    .team-section .team-photo img {
        height: 100%;
        width: 100%;
    }
    
    .team-section .team-photo .slide .memberImg {
        width: 70%;
        height: 100%;
    }    
    .info{
        width:300px;
    }
    .team-section .sub-title h3 {
        font-size: 22px;
    }
    .alignbottom{
        position: relative;
    }
    .team-section .profile{
        padding:20px 0;
    }
    .team-section .profile ul li{
        padding-right:10px;
        width:100%;
    }

    .team-section .profile ul li:first-child{
        display: none;
    }

    .team-section .team-photo .slide{
        width:350px;
        height:250px;
    }

    .teamslider{
        display: block;
    }

    .slide-inner{
        width:100%;
    }
    .slide-inner img{
        width:100%;
    }

    .about-team{
        width:100%;
    }

    .about-team p{
        font-size:15px!important;
        margin-top:0!important;
        padding:0;
    }

    .slider-list .navigation-team{
        width:100%;
    }
    .slider-list-slide{
        display: none;
    }

    .team-section .title h2{
        font-size:44px;
        padding-left:0px;
    }
}


@media (max-width:1240px){
    .slide-inner{
        width:350px!important;
        height:410px!important;
    }
    .slide-inner .img-container{
        width:350px!important;
        height:410px!important;
    }
    .slide-inner .info{
        width:250px;
        bottom:-1px;
    }
    .about-team{
        padding:30px 0 0 20px;
        width:100%;
    }
    .slider-list{
        gap:50px;

    }
    .sub-slide img{
        width:150px;
        height:150px;
    }
}