.progress-bar-container {
  .progressBar {
    display: flex;
    align-items: center;
    border-radius: 80px;
    border: 2px solid #fff;
    height: 64px;
    position: relative;

    &-bar {
      height: 48px;
      border-radius: 80px;
      background: #fff;
      margin: 11px;
      text-align: end;
      color: black;
      span {
        position: absolute;
        right: 2%;
        top: 25%;
        color: #fff;
        text-align: center;
        font-family: Poppins400;
        font-size: 16px;
      }
      &.blink{
        animation: blinkAnimation 3s linear;
        animation-iteration-count: infinite;
      }
    }
  }
}

@keyframes blinkAnimation {
  25%{
    opacity: 1;
  }
  50%{
    opacity: 0.5;
  }
  75%{
    opacity: 1;
  }
}