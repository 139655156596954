.technologies {
  padding:70px 0;    
  background: linear-gradient(-90deg, #ec1a1a47, #1516185c 65%);
  z-index: 0;
  position: relative;
  .nav-tabs {
    border: none;
    justify-content: center;
    .nav-link {
      color: #fff;
      padding: 19px 32px 20px 32px;
      border: none;
      border-radius: unset;
      font-family: Poppins400;
      font-size: 16px;
      &.active {
        background-color: #ec1a1a;
        color: #fff;
      }
    }
  }
  .title{
    text-align: center;
  }
  .tech {
    align-items: center;
    display: flex;
    flex-direction: column;
    margin-right: 20px;
    &-container.active {
      display: flex;
      justify-content: center;
      margin-top: 62px;
    }
  }
}
