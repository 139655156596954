.portfolio-head{
    background:#161719;
    padding:100px 0 70px;
    color:#fff;
    height:400px;
    display: flex;
    align-items: end;
}
.portfolio{
    padding:200px 0;
    .icons{
        background: #fff;
        border-radius: 100%;
        padding:10px;
        img{
            width:40px;
        }
    }
}
.port1{
    background:#280150;
}
.port2{
    background: #43A536;
}
.port3{
    background: #154DAC;
}
.port4{
    background: #151617;
}

.cta-section{
    background:#161719;
    text-align: center;
    padding:100px 0;
    color:#fff;
}