.about-banner-m{
    position: relative;
    background: linear-gradient(81.71deg, #151618 42.68%, #FF0000 129.18%);
    padding:100px 0 70px;
    color:#fff;
    img{
        position: absolute;
        right:0;
        height:800px;
    }
}

.our-story-section{
    padding:100px 0;
    background: #161719;
    h6{
        font-size:28px;
    }

    .cards{
        padding-top: 30px;
        img{
            padding-bottom: 15px;
        }
        h3{
            margin-bottom: 0;
        }
        span{
            color:#EC1A1A;
        }
    }
}

.our-value-section{
    background: linear-gradient(-59.49deg, #151618 35.53%, #FF0000 111.98%);
    padding:100px 0;
    h4{
        font-family:Arial,sans-serif!important;
        font-weight: 400;
        span{
            color:#fff;
        }
    }
    position: relative;
    #vission1{
        position: absolute;
        right:0;
        bottom:10%;
    }
}

.aboutlogo{
    padding: 100px 0;
    background: #161719;
    position: relative;
    img{
        width:90%;
    }
}

.ourculture{
    padding: 100px 0;
    background: #161719;
    .culture-img{
        img{
            padding-bottom:2rem;
            width:110%;
        }
        h4{
            font-family: 'Poppins', sans-serif!important;
            font-weight: 100!important;
        }
    }

}

.serviceavail{
    padding: 100px 0;
    background: #161719;
    .serv-card-wrapper{
        padding:100px 0 0;
        .business-card{
            text-align: center;
            background: #fff;
            border-radius: 10px;
            color:#000;
            padding:20px;
            img{
                margin-top: -50px;
                padding-bottom: 20px;
            }
        }
    }
}