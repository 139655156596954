.carreer-main-head{
    position: relative;
    background: linear-gradient(45deg, #151618 42.68%, #FF0000 129.18%);
    padding:150px 0 70px;
    color:#fff;
    h2{
        color:#fff;
    }
    p{
        color:#fff;
    }
}

.btn-white{
  border-radius: 100px;
  background: #fff;
  border:none;
  padding:15px 40px;
}

.training-section{
    padding: 100px 0;
    background: #0B0E0E;
    .g-wrapper{
      h2{
        color:#fff;
        width:50px;
        height:50px;
        padding:5px;
        border:1px solid #fff;
        border-radius: 100px;
        font-size: 30px!important;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
}


.vacancy-sec{
    padding:100px 0;
    color:#fff;
    background: #0B0E0E;
    .accordion-item{
      margin:0;
      border-radius: 0;
    }
    .accordion-body{
      color:#fff;
      padding:30px 0;
    }
    .accordion-button,.accordion-button:not(.collapsed){
      background: transparent;
      color:#fff;
      padding:15px 0;
      box-shadow:inset 0 calc(-1* var(--bs-accordion-border-width)) 0 #4b4b4b;
    }
    .accordion-button::after{
      width:30px;
      height:30px;
      background-image:url('../../../assets/Add.png')!important;
      background-size: cover!important;
    }
    .accordion-button:not(.collapsed)::after{      
      background-image:url('../../../assets/minus.png')!important;
    }

}

.query-section{
    padding:50px 0 100px;
    h3{
        color:#000;
        padding-bottom:40px;
    }
}

.accordion {
    display: flex;
    flex-direction: column;
    gap: 10px;
}
  
.accordion-item {
    background-color: var(--background-color);
    border-radius: 5px;
    margin:30px 0;
    border:1px solid #00000020;
    padding:10px 0;
    color:#000;
}
  
.accordion-item .accordion-item-description-wrapper hr {
    border: none;
    border-top: 1px solid #00000055;
    visibility: visible;
  }
  
.accordion-item.open .accordion-item-description-wrapper hr {
    visibility: visible;
  }
  
.accordion-item .accordion-item-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
    cursor: pointer;
  }
  
.accordion-item .accordion-item-header .accordion-item-header-title {
    font-weight: 600;
  }
  
.accordion-item .accordion-item-header .accordion-item-header-icon {
    transition: var(--transition);
  }
  
  
    .accordion-item.open
    .accordion-item-header
    .accordion-item-header-icon {
    transform: rotate(-180deg);
  }
  
   .accordion-item .accordion-item-description-wrapper {
    display: grid;
    grid-template-rows: 0fr;
    overflow: hidden;
    transition: var(--transition);
  }
  
   .accordion-item.open .accordion-item-description-wrapper {
    grid-template-rows: 1fr;
  }
  
  
    .accordion-item
    .accordion-item-description-wrapper
    .accordion-item-description {
    min-height: 0;
  }
  
  
    .accordion-item
    .accordion-item-description-wrapper
    .accordion-item-description
    p {
    padding: 10px;
    line-height: 1.5;
  }
  
  
    .accordion-item.open
    .accordion-item-description-wrapper
    .accordion-item-description
    p {
  }
  