h1 { 
  font-family: Public Sans;
  font-size: 66px !important;
  font-style: normal;
  font-weight: 900 !important;
  line-height: 94.395px !important; //143.023%;
}

h2 {
  font-family: Poppins700;
  font-size: 54px !important;
  font-style: normal;
  font-weight: 700 !important;
  line-height: normal;
}

h3 {
  color: #202020;
  font-family: Poppins500;
  font-size: 43px !important;
  font-style: normal;
  font-weight: 500 !important; 
}

h4 {
  color: #fff;
  font-family: Poppins700;
  font-size: 32px !important;
  font-style: normal;
  font-weight: 700 !important; 
}

h5 {
  color: #fff;
  font-family: Poppins700;
  font-size: 24px !important;
  font-style: normal;
  font-weight: 700 !important;
  line-height: normal;
}

p {
  font-family: Poppins400;
}

.swiper {
  &-button-prev {
    width: 72px !important;
    height: 51px !important;
    background-image: url(./assets/arrowPrev.svg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: 24px 24px;
    background-position: center;
    padding: 14px 36px;
    background-color: #fff;
    border-radius: 999px;
    transition: background 0.4s ease;
    &::after {
      content: none !important;
    }
    &:hover {
      background-color: #4c4c4c;
      background-image: url(./assets/arrowPrevHover.svg);
    }
  }

  &-button-next {
    width: 72px !important;
    height: 51px !important;
    background-image: url(./assets/arrowNext.svg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: 24px 24px;
    background-position: center;
    padding: 14px 36px;
    background-color: #fff;
    border-radius: 999px;
    transition: background 0.4s ease;

    &::after {
      content: none !important;
    }
    &:hover {
      background-image: url(./assets/arrowNextHover.svg);
      background-color: #4c4c4c;
    }
  }
}


body{
  overflow-x:hidden ;
}

html{
  overflow-x: hidden;
}