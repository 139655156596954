.bannersection{
    padding:100px 0;
    color:#000;
    display: flex;
    align-items: center;
    height:700px;
}

.techs{
    padding:100px 0;
    color:#000;
    .icon-ellipse{
        height:100px;
        width:100px;
        border-radius: 100px;
        padding:20px;
        box-shadow: 10px 10px 15px #00000020;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 20px;
    }
}