.quote-section{
    position: relative;
    margin-top:50px;
    margin-bottom: 100px ;
    padding:100px 0;
    background: linear-gradient(90deg, rgba(21, 22, 24, 0.47) 0%, rgba(243, 0, 0, 0.47) 26.35%, rgba(255, 0, 0, 0.47) 43.96%, rgba(21, 22, 24, 0.47) 65.84%, rgba(105, 6, 6, 0.47) 88.52%, rgba(21, 22, 24, 0.47) 100%);
    
    #border{
        width:100%;
        display: none;
        position: absolute;
    }
    .quote-wrapper{
        padding:100px 70px ;
        border:5px solid #ffffff50;
        border-left:none;
        position: relative;
        &::after{
            content:'';
            width:5px;
            height:45px;
            background: #ffffff50;
            position: absolute;
            left:0;
            top:0;
        }
        &::before{
            content:'';
            width:5px;
            height:165px;
            background: #ffffff50;
            position: absolute;
            left:0;
            bottom:0;
        }
        svg{
            position: absolute;
            top:20%;
            left:-2%;
        }
        h3{
            font-size: 35px!important;
            color:#fff!important;
            text-align: center;
        }
    }
}