.industries {
  margin: 81px auto 105px auto;
  &-swiper {
    .swiper {
      &-slide {
        text-align: center;
        margin-top: 102px;
        width: 197px;
        height: 268px;
        border-radius: 49px;
        padding: 62px 37px 26px 37px;
        background-color: rgba(255, 255, 255, 0.93);
        &:hover {
          transition: background-color 0.5s linear;
          cursor: pointer;
          background-color: #e05a32;
          .content {
            &-title {
              color: #fff !important;
              transition: color 0.5s linear;
            }
            img {
              filter: invert(100%);
              transition: filter 0.5s linear;
            }
          }
        }

        .content {
          &-title {
            color: #000;
            font-family: Neue Haas Grotesk Text Pro;
            font-size: 18px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            text-transform: uppercase;
          }
          img {
            width: 85px;
            height: 81px;
            margin: 77px 19px 0 19px;
          }
        }
      }
      &-button {
        &-prev {
          top: 10%;
        }
        &-next {
          top: 10%;
          right: unset;
          left: 8%;
        }
      }
    }
  }
}
