.GradientBG {
  z-index: -1;
  height: 946px;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}
.BlackBG {
  left: 0;
  position: absolute;
  top: 670px;
  width: 100%;
  z-index: -1;
}
.fullBlackContainer {
  background: #151618;
  margin-top: 0;
}

