.contact-banner{
    padding:100px 0;
    height:600px;
    display: flex;
    align-items: end;
    h3{
        font-weight: bold!important;
        font-size: 60px!important;
        padding-bottom: 20px;
    }
    .contact-info{
        padding-left: 50px;
        font-size: 20px;
        p{
            font-weight: bold;
        }
    }
}
.contact-form{
    padding:100px 0;
    .consult-card{
        width: 583px;
        height: 460px;
        padding:20px 50px;
        border-radius: 9px ;
        background: #ffffff10;
        display: flex;
        align-items: center;
        img{
            padding-bottom: 10px;
        }        
       
        h3{
            font-size: 35px!important;
            padding-bottom: 10px;
        }
        button{
            width:200px;
            height:60px;
            border-radius: 100px;
        }     

    }
    form{
        .d-flex{
            div{
                width:100%
            }
        }
        label{
            width:100%;
            font-size: 18px;
            padding-bottom: 30px;
            input{
                width:100%;
                border:none;
                border-bottom:1px solid #ffffff50;
                background: transparent;
                padding:10px 0;
                color:#fff;
            }
            textarea{
                width:100%;
                border:none;
                border-bottom:1px solid #ffffff50;
                background: transparent;
                color:#fff;
            }
        }
        input[type="submit"]{
            
            border:none;
            width:200px;
            height:60px;
            border-radius: 100px;
            background: #fff;
        }
    }
    
}

.contact-wrapper{
    background: linear-gradient(116.78deg, #151618 48.18%, #FF0000 117.05%);
}