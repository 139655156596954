.vertical-sub-menu {
  width: 100%;
  #v-tab {
    background: #e05a32;
    padding: 56px 0 56px 0;
    .nav-link {
      padding: 19px 0px 19px 60px;
      text-align: left;
      color: #fff;
      font-family: Poppins500;
      font-size: 18px;
      margin-bottom: 21px;
      &.active {
        background: #000;
      }
    }
    &Content {
      .tab-pane {
        margin: 75px 0 61px 61px;
        flex-direction: column;
        flex-wrap: wrap;
        &.active.show {
          columns: 2;
          -webkit-columns: 2;
          list-style: none;
        }
        .sub {
          text-decoration: none;
          display: flex;
          gap: 12px;
          padding-bottom: 10px;
          padding-top: 30px;
          color: #000;
          font-family: Poppins500;
          font-size: 18px;
          &:first {
            padding-top: 0;
          }
          &:hover {
            img {
              filter: invert(46%) sepia(56%) saturate(807%) hue-rotate(329deg)
                brightness(88%) contrast(99%);
            }
          }
        }
      }
    }
  }
  .left-pane {
    background-color: #000;
    padding: 64px 54px 0 70px;
    .content {
      color: white;
      margin-bottom: 38px;
      h4 {
        text-align: left;
        display: flex;
      }
    }
  }
}
