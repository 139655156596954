.stats-section{    
    padding:120px 0 80px 0;
    background-color: #0f1011;
    position: relative;
    border-top:1px solid #fff;
}

.wrapper{
    padding:30px 15px;
    width:230px;
    border-radius: 8px;
    height:220px;
    position: relative;
    z-index: 1;
    /* align-items:end; */
    display: flex;
    margin-bottom: 50px;
}

.wrapper::before{
    content:'';
    background: rgba(255, 255, 255, 0.2);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(6.7px);
    -webkit-backdrop-filter: blur(6.7px);
    position: absolute;
    top:0;
    left:0;
    z-index:-1;
    width:210px;
    border-radius: 8px;
    height:220px;
}
.wrapper::after{
    content:'';
    position: absolute;
    top:0;
    left:0;
    z-index: -2;
    background: linear-gradient(#EC1A1A, #000);
    height:inherit;
    width:210px;
    border-radius: 8px;
    transform: rotate(-8deg);
}

.wrapper img{
    margin-bottom:25px;
}

.wrapper h3{
    margin:0;
}

.md-screen{
    display:none!important;
}

@media (max-width:1225px) {
   
.md-screen{
    display:flex!important;
} 
.xl-screen{
    display: none!important;
}
}

@media (max-width:780px) {
    .stats-section .d-flex{
        display: block!important;
    }
}