.services-section-wrapper{
  background : #fff;
  color:#000;
  padding:100px 20px; 
  // z-index: 999;
  width:1300px;
  height:200px;
  margin:20px 0;
  // transform-origin: center top;
  box-shadow:10px -20px 10px #00000020;
}


.fixed-bottom-section {
  width: 100%;
  background: #f0f0f0;
  padding: 20px;
  transition: transform 0.5s ease, position 0.5s ease;
}

.fixed {
  position: fixed;
  bottom: 0;
  left: 0;
  transform: translateY(-100%);
}

.services {
  position: relative;
  height:1400px;
  z-index: 1;
  // &-bg-img {
  //   z-index: 1;
  //   position: absolute;
  //   width: 556px;
  //   height: 159px;
  //   bottom: 33%;
  //   border-radius: 556px;
  //   background: linear-gradient(
  //     180deg,
  //     #468ccc 0%,
  //     #6dc7c1 23.44%,
  //     #c3da4c 50.42%,
  //     #add468 74.74%,
  //     #ef893f 100%
  //   );
  //   filter: blur(121.5px);
  // }
  &-section {
    position: relative;
    z-index: 2;
    .title {
      margin-top: 50px;  
    }
    ul {
      columns: 2;
      -webkit-columns: 2;
      -moz-columns: 2;
    }
    .card-body {
      background-color: transparent;
    }
    .sub {
      &.accordion-item {
        color: unset;
        background-color: unset;
        border: unset;
      }
      .accordion-button {
        color: unset;
        background-color: unset;
        box-shadow: unset;
        align-items: flex-start;

        &::after {
          background-image: url(../../../assets/Home/expandBtn.png);
          width: 22px;
          height: 22px;
          background-size: 100%;
        }
        &:not(.collapsed)::after {
          background-image: url(../../../assets/Home/collapseBtn.png);
        }
      }
      border-top: 1px solid #fff !important;
      padding-top: 30px;
      margin-bottom: 20px;
      &:has(.collapse:not(.show)) {
        border-top: 1px solid rgb(255 255 255 / 30%) !important;
      }
      &-title,
      .num {
        font-family: Poppins700;
        font-size: 18px;
      }
      &-title {
        column-gap: 17px;
        display: flex;
        &:has(+ .collapse:not(.show)) {
          margin-right: 50%;
        }
        img {
          width: 24px;
          height: 24px;
        }
      }
      li {
        font-family: Poppins400;
        font-size: 16px;
      }
    }
  }
  &-list {
    display: list-item !important;
    overflow: unset !important;
    list-style: disc;
    width: fit-content;
    width: -moz-fit-content
  }
}
