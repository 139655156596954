.featuresection{
    padding:100px 0;
    background: #161719;
    
    .title{
        padding-bottom: 50px;
        h3{
            font-size: 35px!important;
        }
    }
    .glowing-card{
        background: #EC1A1A;
        padding:60px 50px;
        border-radius:30px;
        border:1px solid #EC1A1A;
        margin:0 0 30px;
        min-height: 400px;
        ul{
            padding-left:80px;
        }
    }
}

.preview-section{
    padding:100px 0;
    background: #161719;
    .title{
        padding-bottom: 50px;
        h3{
            font-size: 55px!important;
            font-weight: bold!important;
        }
        span{
            color:#EC1A1A;
        }
    }
    .preview-wrapper{
        padding-bottom:50px;
        img{
            padding-bottom: 30px;
        }
        h4{
            font-family: 'poppins'!important;
            font-weight: 100!important;
        }
    }
}
