.schedular {
  position: fixed;
  top: 0%;
  z-index: 99999;
  background: #fff;
  height: 100%;
  width: 100%;
  display: none;
  padding: 40px 0;
  box-shadow: 10px 6px 25px 35px #0000000f;
}
.show {
  display: block;
}
.hide {
  display: none;
}

.col50 {
  width: 50%;
}
.Close-schedular {
  position: absolute;
  top: 5%;
  right: 5%;
  cursor: pointer;
}

.schedular::before {
  content: "";
  position: absolute;
  right: 200px;
  top: -15px;
  width: 0;
  height: 0;
  border: 15px solid transparent;
  border-top: 0;
  border-bottom: 20px solid #fff;
}

.s-head {
  text-align: center;
}

.s-head span {
  font-family: "syne", sans-serif;
  font-size: 24px;
  font-weight: bold;
}

.s-form {
  width: 80%;
  margin: 50px auto;
}
.s-form label {
  font-family: "syne", sans-serif;
  font-size: 20px;
  font-weight: 600;
  color: #000;
  width: 100%;
}

.s-form input {
  border: 1px solid #cccccc;
  margin-top: 10px;
  margin-bottom: 15px;
  padding: 10px;
  font-family: "roboto";
  font-weight: normal;
  font-size: 16px;
  color: #000;
  width: 100%;
}

.s-form input::placeholder {
  font-family: "roboto";
  font-weight: normal;
  font-size: 16px;
  color: #000;
}

/* //calander styling */
.react-calendar {
  float: right;
  padding: 15px 10px;
  width: 85% !important;
  border: none !important;
  box-shadow: 0 6px 32px 0 #00000020;
}

.shadowBox {
  float: right;
  padding: 25px 50px;
  height: 420px;
  width: 85% !important;
  border: none !important;
  box-shadow: 0 6px 32px 0 #00000020;
  font-size: 20px;
  color: #000;
  font-family: "syne";
  font-weight: 700;
  line-height: 50px;
}

.shadowBox img {
  width: 1vw;
}

.tab .navigationbtn {
  border: none;
  background: transparent;
  font-size: 18px;
  font-family: "syne";
  font-weight: 600;
  color: #1e44d3;
  padding-left: 0;
  padding-top: 10px;
  cursor: pointer;
}

.tab .submit {
  margin-left: 20px;
  background: #0049dc;
  color: #fff;
  padding: 10px 15px;
}
.react-calendar__tile:disabled {
  background-color: transparent !important;
}


.react-calendar__tile--active {
  background: #E05A32 !important;
  color: #fff !important;

}

.react-calendar__month-view__weekdays__weekday {
  padding: 10px !important;
  font-family: syne !important;
  font-size: 12px;
  text-decoration: none;
}

.react-calendar__month-view__weekdays__weekday abbr {
  text-decoration: none !important;
  font-size: 13px !important;
}

abbr {
  font-family: syne;
  font-size: 16px !important;
  font-weight: 700 !important;
}

.react-calendar {
  button {
    background: none;
    border: none;
  }
  &__navigation__label {
    &__labelText {
      font-family: syne;
      font-size: 21px;
      font-weight: bold;
    }
  }
}

.react-calendar__tile {
  padding: 15px 20px !important;
}

.time .col-6 {
  position: relative;
  margin: 10px 0;
  overflow: hidden;
}

.time input[type="radio"] {
  opacity: 0.01;
  z-index: 100;
  position: absolute;
  width: 100%;
  height: 100%;
  cursor: pointer;
}

.time input[type="radio"]:checked + label,
.Checked + label {
  background: #E05A32;
  color: #fff;
}
.time label {
  padding: 15px 20px;
  border: 1px solid #ccc;
  cursor: pointer;
  z-index: 90;
  font-size: 16px;
  text-align: center;
  font-family: roboto;
  font-weight: 400;
}

.schedule-inner select {
  width: 100%;
  padding: 10px;
  border: 1px solid #c6c6c6;
}

/* 
  .tab{
    display: none;
  } */

/* // Schedule Form Ends // */

/* //Menu Section// */

.menusection {
  position: fixed;
  top: 100%;
  left: 100%;
  transform: translate(100%, 100%);
  overflow: hidden;
  height: 100%;
  width: 100%;
  z-index: 999;
  color: #fff;
  background: transparent;
  opacity: 0;
}

.menubgimg {
  //   background: url("../../img/menu-2.jpg");
  background-position: center;
  height: 100vh;
  background-size: cover;
  background-repeat: no-repeat;
}

.menubg {
  position: relative;
  background-color: #0049dc;
}

.closebtn {
  position: relative;
  top: 20px;
  right: 20px;
  z-index: 9999;
  float: right;
  cursor: pointer;
  border-radius: 50%;
}

.closebtn svg {
  scale: 0.7;
  transition: all 0.6s;
}

.closebtn:hover svg {
  scale: 0.6;
}
