.coming-soon {
  // background-image: url(../../../assets/PinkBlueGradient.svg);
  // background-color: black;
  // background-repeat: no-repeat;
  // background-size: 100% 100%;
  // height: 100vh;
  &-container {
    padding: 2.5% 0 0 0;
    .container {
      @media (min-width: 768px) {
        width: 55.25%;
      }
      .row * {
        text-align: center;
      }
    }
    .content {
      margin: auto;
      margin-top: 17.2%;
      margin-bottom: 9.6%;
      h5 {
        margin-bottom: 2.55%;
      }
    }
    .logo {
      width: 194px;
      height: 69px;
      margin: auto;
      img {
        display: inline-block;
        vertical-align: middle;
        max-height: 100%;
        max-width: 100%;
      }
    }
  }
  .copyright {
    border-top: 1px solid #1d211f;
    position: absolute;
    bottom: 0;
    width: 100%;
    .container {
      padding: 0;
    }
    &-text,a {
      color: #686667;
      font-family: Poppins400;
      font-size: 12px;
      line-height: 18.3px;
      padding: 18px 0 22px 0;
    }
  }
}

body:has(.coming-soon){
  background-image: url(../../../assets/BluePinkGradient.svg);
  background-color: black;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100%;
}