.grow-business {
  padding-bottom: 173px;
  background: #0f1011;
  .rectangle {
    background: #0f1011;
    height: 400px;
    width: 100%;
  }
  .main-container {
    margin-top: -205px;
  }
}
