.home-banner {
  margin-top: 115px;
  margin-bottom: 41px;
  position: relative;
  height: 389px;
  .black-curve {
    &::after {
      content: "";
      right: 0;
      left: 0px;
      bottom: -192px;
      height: 152px;
      background: black;
      position: absolute;
      z-index: -1;
      border-radius: 51% 49% 48% 52% / 49% 50% 50% 51%;
    }
  }
  .title {
    margin-top: 26px;
    margin-bottom: 76px;
    h3 {
      color: #202020;
      font-family: Poppins500;
      font-size: 43px;
      font-style: normal;
      font-weight: 500;
      line-height: 66.822px;
    }
    img {
      padding: 18px;
    }
    &-text {
      font-family: Poppins400;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 16.226px; //115.9%;
      background: linear-gradient(
        90deg,
        #63c1ca 0%,
        #4985c0 35.62%,
        #5772b7 100%
      );
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }
  .banner-img {
    display: flex;
    align-items: center;
  }
  .banner-point {
    &:hover {
      img {
        filter: invert(51%) sepia(59%) saturate(4340%) hue-rotate(343deg)
          brightness(93%) contrast(88%);
      }
      .heading {
        color: #e05a32;
      }
    }
    img {
      width: 24px;
      height: 24px;
      flex-shrink: 0;
    }
    .heading {
      color: #000;
      font-family: Poppins500;
      font-size: 18px;
      font-style: normal;
      font-weight: 500;
      line-height: 27.972px;
      margin-top: 5px;
      margin-bottom: 9px;
    }
    .content {
      color: #000;
      font-family: Rubik;
      font-size: 14px;
      font-style: normal;
      font-weight: 300;
      line-height: normal;
      margin-top: 9px;
      margin-bottom: 16px;
    }
  }
  .swiper-pagination {
    display: flex;
    margin: auto;
    justify-content: space-around;
    margin-top: 15px;
    height: 30px;
    width: 150px !important;

    &-bullet {
      margin-right: 19px;
      width: 24px !important;
      height: 24px !important;
      background-color: #e05a32;
      border-radius: 50%;
      border: 7px solid #fff;
      &-active {
        box-shadow: 0 0 0 1px #000;
      }
    }
  }
  .container {
    &-left {
      // transform: translateX(100%);
      opacity: 0;
    }
    &-right {
      // transform: translateX(-100%);
      opacity: 0;
    }
  }
  .swiper-slide-active {
    .container {
      &-left {
        // transform: translateX(0);
        opacity: 1;
        transition: opacity 2s ease;
        transition-delay: 1.5s;
        animation: animateLeft 2s linear;
        animation-delay: 0.2s;

      }
      &-right {
        // transform: translateX(0) !important;
        opacity: 1;
        transition: opacity 2s ease;
        transition-delay: 1.5s;
        animation: animateRight 2s linear;
        animation-delay: 0.2s;
      }
    }
  }
}

@keyframes animateRight {
  0%{
    transform: translateX(-100%);
    opacity: 0;
  }
  50%{
    transform: translateX(30%);
    opacity: 0.5;
  }
  100%{
    transform: translateX(0%);
    opacity: 1;
  }
}

@keyframes animateLeft {
  0%{
    transform: translateX(100%);
    opacity: 0;
  }
  50%{
    transform: translateX(-30%);
    opacity: 0.5;
  }
  100%{
    transform: translateX(0%);
    opacity: 1;
  }
}