.CurvedSwiper {
  background-color: #0f1011;
  &.swiper::before {
    content: "";
    right: -10px;
    left: -10px;
    top: -7.2rem;
    height: 13rem;
    background: #0f1011;
    position: absolute;
    z-index: 999;
    border-radius: 51% 49% 48% 52% / 49% 50% 50% 51%;
  }
  .swiper {
    &-slide {
      width: 308px !important;
      height: 585px !important;
      flex-shrink: 0 !important;
      .content {
        width: 308px;
        height: 176px;
        flex-shrink: 0;
        background: #537bc0;
        padding: 21px 33px 19px 33px;
        color: white;
        display: none;
      }
      &-active {
        .content {
          display: block;
        }
      }
      &.container {
        &1 {
          .row:first-child {
            background-color: #1e4e5a;
          }
          img {
            margin: 132px 30px 8px 48px;
            width: 229px;
            height: 269px;
          }
        }
        &2 {
          .row:first-child {
            background-color: #ece7d7;
          }
          img {
            margin: 102px 6px 0px 6px;
            width: 292px;
            height: 307px;
          }
        }
        &3 {
          .row:first-child {
            background-color: #01a95b;
          }
          img {
            margin: 128px 30px 36px 30px;
            width: 246px;
            height: 246px;
          }
        }
        &4 {
          .row:first-child {
            background-color: #0b332b;
          }
          img {
            margin: 180px 25px 97px 25px;
            width: 258px;
            height: 132px;
          }
        }
        &5 {
          .row:first-child {
            background-color: #1a2f32;
          }
          img {
            margin: 151px 25px 49px 25px;
            width: 231px;
            height: 209px;
          }
        }
      }
    }
    &-button-prev {
      left: 30% !important;
      top: unset !important;
      bottom: 11% !important;
      
    }
    &-button-next {
      right: 30% !important;
      top: unset !important;
      bottom: 11% !important;
    }
  }
}
