.branding {
  margin: 81px auto 105px auto;
  &-swiper {
    position: unset;
    .swiper {
      &-slide {
        width: 301px;
        height: 420px;
        border-radius: 19px;
        padding: 28px 19px 18px 23px;

        .content {
          padding: 150px 0px 18px 8px;
          &-title {
            color: #000;
            font-family: Rubik;
            font-size: 16px;
            padding-top: 24px;
          }
          h5 {
            color: #000;
            padding-bottom: 12px;
            border-bottom: 2px solid black;
          }
        }
      }
    }
    .swiper-button {
      &-prev {
        left: -6.5%;
        bottom: 32%;
        top: unset;
      }
      &-next {
        right: -6%;
        bottom: 32%;
        top: unset;
      }
    }
  }
}
