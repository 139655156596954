$--meteor-delay: 0s;
$--meteor-duration: 2s;
.box.workflow {
  .overlap-group {
    position: relative;
  }
  .lines {
    height: 546px;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    margin-top: -7%;
  }
  .ellipse {
    left: 0;
    position: absolute;
    width: 100%;
    margin-top: 6%;
    z-index: -1;
  }

  .rectangle {
    background-color: #444427;
    border: 1px solid;
    border-color: #e8e71b;
    height: 128px;
    position: absolute;
    width: 100%;
    // margin-top: 11%;
    z-index: -1;
  }

  .text-wrapper {
    padding-top: 18%;
    margin-bottom: 3%;
    text-align: center;
    h4 {
      position: relative;
    }
  }
  .content-container {
    position: relative;
    .number {
      opacity: 0.5;
      font-size: 29px;
      font-weight: 400;
      letter-spacing: 0;
      line-height: 41.5px;
    }
    p {
      font-family: "Rubik", Helvetica;
      width: 90%;
    }
    .text {
      font-family: "Public Sans", Helvetica;
      font-size: 24px;
      font-weight: 900;
      letter-spacing: 0;
      line-height: 34.3px;
      white-space: nowrap;
      display: flex;
      gap: 10px;
    }
    .concept {
      color: #ff7272;
    }
    .budget {
      color: #e7e71b;
    }

    .development {
      color: #0bb180;
    }

    .result {
      color: #e61f82;
    }

    .workflow {
      &Line {
        left: 47.7%;
        position: absolute;
        z-index: 1;
      }
      &Arrow {
        left: 47%;
        position: absolute;
        z-index: 1;
      }
    }
  }

  .ellipse-7 {
    background-color: #e05a32;
    border-radius: 5px;
    height: 10px;
    left: 977px;
    position: absolute;
    top: 316px;
    width: 10px;
  }

  .ellipse-8 {
    background-color: #e05a32;
    border-radius: 4.5px;
    filter: blur(4px);
    height: 9px;
    left: 1146px;
    opacity: 0.5;
    position: absolute;
    top: 164px;
    width: 9px;
  }

  .ellipse-9 {
    background-color: #e05a32;
    border-radius: 4.5px;
    filter: blur(4px);
    height: 9px;
    left: 319px;
    opacity: 0.5;
    position: absolute;
    top: 200px;
    width: 9px;
  }
  .meteor-section {
    position: absolute;
    top: -20%;
    &.pointer-events-none {
      pointer-events: none;
      z-index: -1;
    }
    img {
      opacity: 0.7;
        width: 100dvw; 
    }
    .grid {
      position: absolute;
      left: 0;
    }
    .meteor {
      position: absolute;
      width: 9px;
      height: 9px;
      z-index: 0;
      opacity: 0;
      &-1 {
        left: 6%;
        --meteor-delay: 0s;
        --meteor-duration: 4s;
      }

      &-2 {
        left: 15.2%;
        --meteor-delay: 1s;
        --meteor-duration: 6s;
      }
      &-3 {
        left: 24.5%;
        --meteor-delay: 2s;
        --meteor-duration: 5s;
      }
      &-4 {
        left: 34%;
        --meteor-delay: 3s;
        --meteor-duration: 4s;
      }
      &-5 {
        left: 43.4%;
        --meteor-delay: 4s;
        --meteor-duration: 5s;
      }
      &-6 {
        left: 52.8%;
        --meteor-delay: 5s;
        --meteor-duration: 6s;
      }
      &-7 {
        left: 62.2%;
        --meteor-delay: 60s;
        --meteor-duration: 4s;
      }
      &-8 {
        left: 71.5%;
        --meteor-delay: 4s;
        --meteor-duration: 5s;
      }
      &-9 {
        left: 80.8%;
        --meteor-delay: 3s;
        --meteor-duration: 6s;
      }
      &-10 {
        left: 90.3%;
        --meteor-delay: 2s;
        --meteor-duration: 4s;
      }
      &-11 {
        left: 99.5%;
        --meteor-delay: 1s;
        --meteor-duration: 5s;
      }

      &-animation {
        transform: rotate(270deg);
        animation: meteor-animation 3s linear;
        animation-delay: var(--meteor-delay, 0s);
        animation-duration: var(--meteor-duration, 3s);
        animation-iteration-count: infinite;
      }

      &-animation:before {
        content: "";
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        width: 150px;
        height: 1px;
        background: linear-gradient(
          90deg,
          #e05a32,
          #ca6b4f,
          #492317,
          transparent
        );
      }

      &-animation:after {
        content: "";
        position: absolute;
        inset: 0;
        top: 0px;
        left: -8px;
        height: 10px;
        width: 10px;
        border-radius: 50%;
        background: #f9764f;
        filter: blur(2px);
      }
    }

    @keyframes meteor-animation {
      0% {
        bottom: 100%;
        opacity: 0;
      }

      70% {
        opacity: 1;
      }

      to {
        bottom: 0;
        opacity: 0;
      }
    }
  }
}
.vl-dot-container {
  display: flex;
  justify-content: end;
  .vl {
    border-right: 2px solid white;
    height: 100%;
    display: flex;
    align-items: center;
    &-top {
      align-items: flex-start;
    }
    &-bottom {
      height: 75%;
      align-items: flex-end;
    }
    .dot {
      margin-right: -8px;
      width: 15px;
      height: 15px;
      background-color: white;
      border-radius: 50%;
      display: block;
      transition: width 1s linear, height 1s linear, background-color 1s linear,
        margin-right 1s linear;
      &.active {
        width: 55px;
        height: 55px;
        margin-right: -27px;
        background-image: url(../../../assets/ArrowRight.svg);
        background-repeat: no-repeat;
        background-size: 70% 70%;
        background-position: center;
        background-color: yellow;
        transition: width 1s linear, height 1s linear,
          background-color 1s linear, margin-right 1s linear,
          background-image 1s linear;
      }
    }
  }
}
