.footer {
  background-color: #040907;
  .container {
    padding-top: 88px;
    .content {
      &-left {
        .title {
          color: #fff;
          font-family: Poppins400;
          font-size: 50px;
          line-height: 49px;
          margin-bottom: 52px;
        }
        .helpBtn {
          display: inline-flex;
          padding: 20px 50px;
          align-items: flex-start;
          gap: 10px;
          border-radius: 29px;
          border: 1px solid #707070;
          background: #161616;
          color: #fff;
          font-family: Poppins600;
          font-size: 14px;
          line-height: 16.2px;
        }
        p {
          color: #686b6a;
          margin-top: 55px;
        }
      }
      &-center {
        p {
          color: #686b6a;
        }
        a {
          color: #fff;
          font-family: Poppins400;
          font-size: 17px;
          line-height: 25.925px;
        }
        .mb-11 {
          margin-bottom: 11px;
        }
        .mb-13 {
          margin-bottom: 13px;
        }
        .mb-40 {
          margin-bottom: 40px;
        }
        .mt-40 {
          margin-top: 40px;
        }
        .mt-48 {
          margin-top: 48px;
        }
      }
      &-right {
        .platforms {
          p {
            color: #686b6a;
            padding: 0;
            margin-top: 48px;
            margin-bottom: 12px;
          }
          a {
            padding: 0;
          }
        }
        a {
          color: #fff;
          font-family: Poppins400;
          font-size: 17px;
          line-height: 44.88px;
          gap: 13px;
          display: flex;
          align-items: center;
          img {
            padding: 0;
            width: 24px;
            height: 24px;
          }
        }
      }
    }
  }
  .copyright {
    border-top: 1px solid #1d211f;
    .container {
      padding: 0;
    }
    &-text,a {
      color: #fff;
      font-family: Poppins400;
      font-size: 12px;
      line-height: 18.3px;
      padding: 18px 0 22px 0;
    }
  }
  a{
    text-decoration: none;
    width: -moz-fit-content;
    width: fit-content;
  }
}
