.about-company{
    padding:50px 0;
}


.about-company span{
    font-family: 'syne';
    font-size:21px;
    font-weight: 600;
    color:#fff;
}

.about-company h3{
    font-size:33px;
    font-weight: 700;
    margin-bottom:30px;
}

.about-company button{
    font-size: 16px;
    background-color: #EC1A1A;
    color: #fff;
    padding: 20px 50px;
    border: none;
    border-radius: 8px;

}

.about-company p{
    color:#fff;
}


.about-company .about-com-img {
    position: relative; 
}