@font-face {
  font-family: Poppins700;
  src: url(Poppins-Bold.ttf);
}

@font-face {
  font-family: Poppins500;
  src: url(Poppins-Medium.ttf);
}

@font-face {
  font-family: Poppins400;
  src: url(Poppins-Regular.ttf);
}
@font-face {
  font-family: Poppins300;
  src: url(Poppins-Light.ttf);
}

@font-face {
  font-family: Poppins600;
  src: url(Poppins-SemiBold.ttf);
}
