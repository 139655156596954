.top-gradient {
  position: absolute;
  top: -5%;
  left: -5%;
  z-index: -1;
}

.navbar {
  padding: 15px 20px 15px 20px;
  background-color: transparent;
  position: absolute;
  width:100%;
  z-index:999;
  // &-collapse {
  //   flex-direction: row-reverse;
  // }
  &-schedular {
    border: none;
    left: 0;
  }
  .nav-item {
    display: flex;
    align-items: center;
    &.dropdown {
      position: unset;
    }
    .dropdown-menu {
      top: 100%;
      left: 0;
      margin-top: var(--bs-dropdown-spacer);
    }
  }

  .nav-link {
    color: #fff;
    font-family: Poppins500;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }

  .left-menu {
    background: none;
    border: none;
    margin-top: -15px;
    margin-right: 60px;
    &-container {
      display: none;
      background-image: url(../../../assets/Header/leftMenuBg.png);
      height: auto;
      width: 100%;
      position: absolute;
      top: 0;
      left: 0;
      background-repeat: no-repeat;
      background-size: 100% 100%;
      padding: 204px 100px 234px 100px;
      z-index: 999;
      &.show {
        display: block;
      }
      .closeBtn {
        position: absolute;
        top: 0;
        left: 0;
        cursor: pointer;
      }
      a {
        color: #fff;
        font-family: Poppins400;
        font-size: 17px;
        line-height: 25.925px;
      }
      .social-icon {
        margin-top: 38px;
        display: flex;
        gap: 22px;
      }
      h2,
      h5 {
        font-weight: 500 !important;
        text-align: left;
      }
      .mb-32 {
        margin-bottom: 32px;
      }
      .counter {
        h2 {
          display: flex;
        }
        .col-5 {
          border-radius: 20px;
          border: 1px solid #36a2ae;
          padding: 16px 0 23px 27px;
        }
        .row {
          gap: 14px;
          margin-bottom: 12px;
        }
      }
    }
  }
}

/* //Social Media Icons// */

.iconbar{
  position: fixed;
  top:45%;
  right:2%;
  z-index:998;
}

.iconbar ul{
  list-style:none;
  border-radius: 10px;
  padding:0;
}

.iconbar ul li{
  padding:8px 12px;
  border-radius: 0%;
  background-color: #fff;
  // box-shadow:5px 5px 15px #3030302b;
  // background:yellowgreen;
  margin-bottom: 10px;
  width:45px;
  height:45px;
  scale:1;
  transition: 0.3s ease;
}

.iconbar ul li:hover{
  scale:1.2;
  // background:#E05A32;
}

.iconbar svg {
  width:20px; 
  scale:1.2;
  cursor:pointer;
}

.iconbar svg path{
  transition: 0.7s;
}

// .Navbar .menu{
//   display: flex;  
//   justify-content: center;
// }

// .Navbar .menu .d-flex{
//   font-family: 'syne'!important;
//   font-size:17px;
//   font-weight: 500;
//   height:100%;
//   padding: 0;
//   margin:0;
//   gap:20px;
//   list-style-type: none;
//   justify-content:center;
//   align-items: center;
//   width:90%;
// }

// .Navbar .menu .d-flex::before{
//   content:'';
//   position: absolute;
//   left:20%;
//   width: 2px;
//   height:50px;
//   background-color: #22222249;
// }
// .Navbar .menu .d-flex::after{
//   content:'';
//   position: absolute;
//   right:20%;
//   width: 2px;
//   height:50px;
//   background-color: #22222249;
// }

// .Navbar .menu .d-flex li{
//   padding:45px 0;
//   color:#000;
// }

// .Navbar .menu .d-flex li:hover{
//   color:#0049DC;
// }

// .Navbar .menu .d-flex li:hover svg path{
//   fill:#0049DC;
// } 

// .sidebar-btn{
//   display: flex;    
//   position: absolute;
//   left: 0;
//   top: 0;
//   border: none;
//   height:100%;
//   width: 6rem;
//   padding: 0;
//   margin:0;
//   gap:20px;
//   list-style-type: none;
//   justify-content:center;
//   align-items: center;
// }

/* //Responsive// */
@media  (min-width: 1200px) and (max-width: 1440px){
  .iconbar{
    position: fixed;
    top:25%;
    right:2%;
    z-index:998;
  }
}

/**Mobile css***/

@media (min-width: 320px) and (max-width: 480px) {
   
  .Navbar{
      padding:15px 10px;
  }
  
  .Navbar .logo img{
      width:100%;
      height: 100%;
  }

  .Navbar ul li .quickLink{
      display: none;
  }

  .Navbar .row{
      padding:0!important
  }

  .Navbar .menu-container {
    position: fixed;
    top: 10%;
    right: 5%;
    z-index: 9;
  }
  .Navbar .menubtn{
    display: none;
  }

  .menubgimg{
    display:none
  }

  .menubg{
    height:100vh;
  }

  .menu-items{
    left:0;
  }

  .social-icon{
    left:10%;
    right:0;
  }

  .iconbar{
    display: none;
  }

  nav a{
    font-size:35px;
    height:80px;
  }

}


//Header Section// 

.navbar-nav .nav-link.active, .navbar-nav .nav-link.show{
  color:#fff;
}