.heading {
  margin-bottom: 40px;
  margin-top: 52px;
}
.About {
  &1 {
    width: 293px;
    p{
        margin-top: 34px;
    }
  }
  &2 {
    margin-top: 121px;
    width: 267px;
    z-index: 1;
    p{
        margin-top: 23px;
    }
  }
  &3 {
    margin-top: 36px;
    width: 168px;
    img:nth-of-type(2){
        margin-top: 160px;
    }
  }
  &BgImg{
    width: 261.94px;
    height: 360.741px;
    position: absolute;
    right: 0;
    padding: 0;
    top: 22%;
  }
}
.description{
    margin-top: 28px;
}